@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

.landing {
  font-family: "Lato", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url("../images/kingswap_logo.svg") no-repeat center center;
  background: linear-gradient(
      90deg,
      rgba(43, 43, 43, 0.5),
      rgba(83, 51, 237, 0.6),
      rgba(43, 43, 43, 0.5)
    ),
    url("../images/bg-3.jpg") no-repeat center center;
  background-attachment: fixed;
  background-size: cover;

  &__logo {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    padding: 40px;
    display: block;
    background: url("../images/kingswap_logo.svg") no-repeat center center;
  }

  &__heading,
  &__sub {
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    color: #fff;
    margin-top: -6px;
  }

  &__sub {
    font-size: 22px;
    font-weight: 400;
  }

  &__context {
    border-radius: 5px;
    padding: 9px;
  }
}
